.actuals__by-project {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 30px;
  right: 30px;
  border-radius: 7px;
  border: 1px solid var(--stroke);
  overflow: auto;
  max-height: calc(100% - 150px);

  .actuals__by-project__month-row {
    height: 34px;
    min-height: 34px;
    display: flex;
    border-bottom: 1px solid var(--stroke);
    min-width: fit-content;
    width: 100%;

    .actuals__by-project__month-row__spacer {
      width: 302px;
      min-width: 302px;
      border-right: 1px solid var(--stroke);
      position: sticky;
      left: 0;
      background-color: var(--background-1);
      z-index: 1;
    }

    .actuals__by-project__month-row__months {
      display: flex;
      min-width: fit-content;
      width: 100%;
    }
  }

  .actuals__by-project__projects-row {
    position: relative;
    height: 56px;
    min-height: 56px;
    min-width: fit-content;
    width: 100%;
    display: flex;

    .actuals__by-project__projects-row__spacer {
      width: 302px;
      min-width: 302px;
      border-right: 1px solid var(--stroke);
      padding: 0 20px;
      position: sticky;
      left: 0;
      background-color: var(--background-1);

      .actuals__by-project__projects-row__spacer__project {
        margin-top: 12px;
        display: flex;
        gap: 10px;

        .spacer__project-color {
          width: 24px;
          height: 24px;
          margin: 3px;
          border-radius: 4px;
        }

        .spacer__project-name {
          font-size: 14px;
          color: var(--text-2);
          margin: 5px 0;
          width: 122px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
        }

        .spacer__project-stats {
          width: 90px;
          height: 30px;
          background-color: var(--background-2);
          border-radius: 7px;

          p {
            font-size: 12px;
            margin: 6px;
            font-weight: 500;
            color: var(--button-outline-text-default);
          }
        }
      }
    }

    .actuals__by-project__projects-row__day {
      min-width: 70px;
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid var(--stroke);

      &.weekend {
        input {
          background-color: var(--background-2);
        }
      }

      &.first-column {
        border-left: unset;
      }

      &__input {
        width: 100%;
        text-align: center;
        height: 100%;
        margin: unset;
        padding: unset;
        font-family: Montserrat;
        color: var(--text-2);
        background-color: var(--background-1);
        outline: none !important;
        border: none;
        font-size: 14px;

        &:focus {
          outline: none !important;
          border: 1px solid var(--text-2) !important;
        }
      }

      .params-popup {
        margin-top: 235px;
        position: fixed;
        width: 360px;
        background-color: var(--background-1);
        border-radius: 7px;
        border: 1px solid var(--stroke);
        padding: 10px;
        z-index: 100;

        &__close-popup {
          height: fit-content;
          cursor: pointer;
        }

        .params-popup__service-payroll {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          .params-popup__field-name {
            flex-grow: 1;
            flex-basis: 0;

            p {
              text-align: left;
              margin-bottom: 0;
              font-size: 14px;
              color: var(--text-2);
            }
          }
        }
      }
    }
  }

  .actuals__by-project__days-row {
    display: flex;
    height: 31px;
    min-width: fit-content;
    width: 100%;
    border-bottom: 1px solid var(--stroke);

    .actuals__by-project__days-row__spacer {
      width: 302px;
      min-width: 302px;
      border-right: 1px solid var(--stroke);
      position: sticky;
      left: 0;
      background-color: var(--background-1);
    }

    .actuals__by-project__days-row__days {
      display: flex;
      min-width: fit-content;
      width: 100%;
    }
  }

  .actuals__by-project__days-row__day.second-row {
    border-left: 1px solid var(--stroke);
  }

  .actuals__by-project__days-row__day {
    min-width: 70px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.first-column {
      border-left: unset !important;
    }

    &.weekend {
      background-color: var(--background-2);
      border-left: 1px solid var(--stroke);
    }

    .actuals__by-project__month-row__month {
      position: absolute;
      margin: 0px 0px 0px 10px;
      text-transform: uppercase;
      font-size: 12px;
      color: var(--text-1);
    }

    .actuals__by-project__days-row__day-text {
      margin-bottom: 0px;
      font-size: 12px;
      color: var(--text-1);
    }
  }

  .actuals__by-project__totals-row {
    display: flex;
    height: 56px;
    min-height: 56px;
    min-width: fit-content;
    width: 100%;
    border-top: 1px solid var(--stroke);
    background-color: var(--background-2);

    .actuals__by-project__totals-row__spacer {
      width: 302px;
      min-width: 302px;
      border-right: 1px solid var(--stroke);
      position: sticky;
      left: 0;
      background-color: var(--background-2);
      text-align: left;

      p {
        font-size: 14px;
        color: var(--text-2);
        margin: 19px 0 0 20px;
      }
    }

    .actuals__by-project__totals-row__total {
      width: 91px;
      min-width: 91px;
      border-right: 1px solid var(--stroke);
      background-color: var(--background-2);

      p {
        font-size: 14px;
        margin: 5px 0 0 0;
      }
    }

    .actuals__by-project__totals-row__days {
      display: flex;
      min-width: fit-content;
      width: 100%;
      border-bottom: 1px solid var(--stroke);
    }
  }

  .days-row__today {
    background: var(--stroke);

    .actuals__by-project__days-row__day-text {
      color: var(--text-1);
    }
  }

  .actuals__by-project__totals-row__day {
    min-width: 70px;
    flex-basis: 0px;
    flex-grow: 1;
    border-left: 1px solid var(--stroke);

    &.first-column {
      border-left: unset;
    }

    .actuals__by-project__totals-row__day-text {
      font-size: 14px;
      color: var(--text-2);
      margin: 19px 0 0 0;
    }
  }
}
